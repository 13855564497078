
  :root {
    --background: 0 0% 100%;
    --background-color: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  /* Manual preflight */

  *,
  ::before,
  ::after {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: #e5e7eb;
  }

  ::before,
  ::after {
    --tw-content: '';
  }

  button {
    background-color: transparent;
    background-image: none;
  }

  button,
  [role='button'] {
    cursor: pointer;
  }

  /*
  1. Use a consistent sensible line-height in all browsers.
  2. Prevent adjustments of font size after orientation changes in iOS.
  3. Use a more readable tab size.
  4. Use the user's configured `sans` font-family by default.
  5. Use the user's configured `sans` font-feature-settings by default.
  6. Use the user's configured `sans` font-variation-settings by default.
  */

  html {
    line-height: 1.5; /* 1 */
    -webkit-text-size-adjust: 100%; /* 2 */
    -moz-tab-size: 4; /* 3 */
    -o-tab-size: 4;
       tab-size: 4; /* 3 */
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; /* 4 */
    font-feature-settings: normal; /* 5 */
    font-variation-settings: normal; /* 6 */
  }

  /*
  1. Remove the margin in all browsers.
  2. Inherit line-height from `html` so users can set them as a class directly on the `html` element.
  */

  body {
    margin: 0; /* 1 */
    line-height: inherit; /* 2 */
  }

  /*
  1. Add the correct height in Firefox.
  2. Correct the inheritance of border color in Firefox. (https://bugzilla.mozilla.org/show_bug.cgi?id=190655)
  3. Ensure horizontal rules are visible by default.
  */

  hr {
    height: 0; /* 1 */
    color: inherit; /* 2 */
    border-top-width: 1px; /* 3 */
  }

  /*
  Add the correct text decoration in Chrome, Edge, and Safari.
  */

  abbr:where([title]) {
    -webkit-text-decoration: underline dotted;
            text-decoration: underline dotted;
  }

  /*
  Remove the default font size and weight for headings.
  */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: inherit;
    font-weight: inherit;
  }

  /*
Prevent `sub` and `sup` elements from affecting the line height in all browsers.
*/

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sub {
    bottom: -0.25em;
  }

  sup {
    top: -0.5em;
  }

  /*
  1. Remove text indentation from table contents in Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=999088, https://bugs.webkit.org/show_bug.cgi?id=201297)
  2. Correct table border color inheritance in all Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=935729, https://bugs.webkit.org/show_bug.cgi?id=195016)
  3. Remove gaps between table borders by default.
  */

  table {
    text-indent: 0;
    border-color: inherit;
    border-collapse: collapse;
  }

  /*
  1. Change the font styles in all browsers.
  2. Remove the margin in Firefox and Safari.
  3. Remove default padding in all browsers.
  */

  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit; /* 1 */
    font-feature-settings: inherit; /* 1 */
    font-variation-settings: inherit; /* 1 */
    font-size: 100%; /* 1 */
    font-weight: inherit; /* 1 */
    line-height: inherit; /* 1 */
    color: inherit; /* 1 */
    margin: 0; /* 2 */
    padding: 0; /* 3 */
  }

  /*
  Removes the default spacing and border for appropriate elements.
  */
  blockquote,
  dl,
  dd,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hr,
  figure,
  p,
  pre {
    margin: 0;
  }

  fieldset {
    margin: 0;
    padding: 0;
  }

  ol,
  ul,
  menu {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  /*
  Prevent resizing textareas horizontally by default.
  */

  textarea {
    resize: vertical;
  }

  /*
  Make sure disabled buttons don't get the pointer cursor.
  */
  :disabled {
    cursor: default;
  }

  /*
  1. Make replaced elements `display: block` by default. (https://github.com/mozdevs/cssremedy/issues/14)
  2. Add `vertical-align: middle` to align replaced elements more sensibly by default. (https://github.com/jensimmons/cssremedy/issues/14#issuecomment-634934210)
    This can trigger a poorly considered lint error in some tools but is included by design.
  */
  img,
  svg,
  video,
  canvas,
  audio,
  iframe,
  embed,
  object {
    display: block; /* 1 */
    vertical-align: middle; /* 2 */
  }

*, ::before, ::after {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  ;
}

::backdrop {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  ;
}
.container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 2rem;
    padding-left: 2rem;
}
@media (min-width: 1400px) {

    .container {
        max-width: 1400px;
    }
}
.pointer-events-none {
    pointer-events: none;
}
.visible {
    visibility: visible;
}
.invisible {
    visibility: hidden;
}
.fixed {
    position: fixed;
}
.absolute {
    position: absolute;
}
.relative {
    position: relative;
}
.sticky {
    position: sticky;
}
.inset-0 {
    inset: 0px;
}
.-bottom-3 {
    bottom: -0.75rem;
}
.-left-3 {
    left: -0.75rem;
}
.-right-3 {
    right: -0.75rem;
}
.-top-px {
    top: -1px;
}
.left-0 {
    left: 0px;
}
.left-2 {
    left: 0.5rem;
}
.right-0 {
    right: 0px;
}
.top-0 {
    top: 0px;
}
.top-1\/2 {
    top: 50%;
}
.z-10 {
    z-index: 10;
}
.z-40 {
    z-index: 40;
}
.z-50 {
    z-index: 50;
}
.z-\[1500\] {
    z-index: 1500;
}
.z-\[1\] {
    z-index: 1;
}
.m-0 {
    margin: 0px;
}
.mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
}
.mx-auto {
    margin-left: auto;
    margin-right: auto;
}
.my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}
.-ml-3 {
    margin-left: -0.75rem;
}
.-mr-3 {
    margin-right: -0.75rem;
}
.mb-2 {
    margin-bottom: 0.5rem;
}
.mb-2\.5 {
    margin-bottom: 0.625rem;
}
.ml-0 {
    margin-left: 0px;
}
.ml-0\.5 {
    margin-left: 0.125rem;
}
.ml-auto {
    margin-left: auto;
}
.mr-2 {
    margin-right: 0.5rem;
}
.mr-auto {
    margin-right: auto;
}
.mt-2 {
    margin-top: 0.5rem;
}
.box-content {
    box-sizing: content-box;
}
.block {
    display: block;
}
.flex {
    display: flex;
}
.inline-flex {
    display: inline-flex;
}
.table {
    display: table;
}
.hidden {
    display: none;
}
.h-10 {
    height: 2.5rem;
}
.h-11 {
    height: 2.75rem;
}
.h-4 {
    height: 1rem;
}
.h-5 {
    height: 1.25rem;
}
.h-6 {
    height: 1.5rem;
}
.h-8 {
    height: 2rem;
}
.h-9 {
    height: 2.25rem;
}
.h-\[1px\] {
    height: 1px;
}
.h-\[350px\] {
    height: 350px;
}
.h-\[36px\] {
    height: 36px;
}
.h-\[3px\] {
    height: 3px;
}
.h-auto {
    height: auto;
}
.h-full {
    height: 100%;
}
.min-h-\[50\%\] {
    min-height: 50%;
}
.w-10 {
    width: 2.5rem;
}
.w-4 {
    width: 1rem;
}
.w-5 {
    width: 1.25rem;
}
.w-6 {
    width: 1.5rem;
}
.w-72 {
    width: 18rem;
}
.w-8 {
    width: 2rem;
}
.w-9 {
    width: 2.25rem;
}
.w-\[1px\] {
    width: 1px;
}
.w-\[316px\] {
    width: 316px;
}
.w-\[330px\] {
    width: 330px;
}
.w-\[36px\] {
    width: 36px;
}
.w-auto {
    width: auto;
}
.w-full {
    width: 100%;
}
.max-w-full {
    max-width: 100%;
}
.flex-1 {
    flex: 1 1 0%;
}
.shrink-0 {
    flex-shrink: 0;
}
.grow {
    flex-grow: 1;
}
.-translate-y-1\/2 {
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.transform {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.cursor-col-resize {
    cursor: col-resize;
}
.cursor-pointer {
    cursor: pointer;
}
.cursor-row-resize {
    cursor: row-resize;
}
.select-none {
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
}
.resize-none {
    resize: none;
}
.resize {
    resize: both;
}
.appearance-none {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
}
.flex-col {
    flex-direction: column;
}
.flex-wrap {
    flex-wrap: wrap;
}
.items-end {
    align-items: flex-end;
}
.items-center {
    align-items: center;
}
.items-stretch {
    align-items: stretch;
}
.justify-start {
    justify-content: flex-start;
}
.justify-center {
    justify-content: center;
}
.justify-between {
    justify-content: space-between;
}
.gap-1 {
    gap: 0.25rem;
}
.overflow-hidden {
    overflow: hidden;
}
.overflow-y-auto {
    overflow-y: auto;
}
.overflow-x-hidden {
    overflow-x: hidden;
}
.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.whitespace-nowrap {
    white-space: nowrap;
}
.break-all {
    word-break: break-all;
}
.rounded {
    border-radius: 0.25rem;
}
.rounded-full {
    border-radius: 9999px;
}
.rounded-lg {
    border-radius: var(--radius);
}
.rounded-md {
    border-radius: calc(var(--radius) - 2px);
}
.rounded-sm {
    border-radius: calc(var(--radius) - 4px);
}
.rounded-t {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}
.rounded-t-lg {
    border-top-left-radius: var(--radius);
    border-top-right-radius: var(--radius);
}
.border {
    border-width: 1px;
}
.border-0 {
    border-width: 0px;
}
.border-b {
    border-bottom-width: 1px;
}
.border-t {
    border-top-width: 1px;
}
.border-solid {
    border-style: solid;
}
.border-none {
    border-style: none;
}
.border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgb(243 244 246 / var(--tw-border-opacity));
}
.border-input {
    border-color: hsl(var(--input));
}
.border-b-gray-100 {
    --tw-border-opacity: 1;
    border-bottom-color: rgb(243 244 246 / var(--tw-border-opacity));
}
.bg-\[rgba\(0\2c 0\2c 0\2c 0\.05\)\] {
    background-color: rgba(0,0,0,0.05);
}
.bg-background {
    background-color: hsl(var(--background));
}
.bg-background\/90 {
    background-color: hsl(var(--background) / 0.9);
}
.bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}
.bg-border {
    background-color: hsl(var(--border));
}
.bg-destructive {
    background-color: hsl(var(--destructive));
}
.bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}
.bg-inherit {
    background-color: inherit;
}
.bg-popover {
    background-color: hsl(var(--popover));
}
.bg-primary {
    background-color: hsl(var(--primary));
}
.bg-secondary {
    background-color: hsl(var(--secondary));
}
.bg-transparent {
    background-color: transparent;
}
.fill-current {
    fill: currentColor;
}
.object-cover {
    -o-object-fit: cover;
       object-fit: cover;
}
.p-0 {
    padding: 0px;
}
.p-1 {
    padding: 0.25rem;
}
.p-2 {
    padding: 0.5rem;
}
.p-3 {
    padding: 0.75rem;
}
.p-4 {
    padding: 1rem;
}
.px-0 {
    padding-left: 0px;
    padding-right: 0px;
}
.px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
.px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}
.px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
}
.px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}
.px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
}
.py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}
.py-1\.5 {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
}
.py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.py-2\.5 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
}
.pl-2 {
    padding-left: 0.5rem;
}
.pl-3 {
    padding-left: 0.75rem;
}
.pr-1 {
    padding-right: 0.25rem;
}
.pr-3 {
    padding-right: 0.75rem;
}
.text-center {
    text-align: center;
}
.font-\[inherit\] {
    font-family: inherit;
}
.text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
}
.text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
}
.text-base {
    font-size: 1rem;
    line-height: 1.5rem;
}
.text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
}
.text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
}
.font-medium {
    font-weight: 500;
}
.uppercase {
    text-transform: uppercase;
}
.capitalize {
    text-transform: capitalize;
}
.italic {
    font-style: italic;
}
.leading-none {
    line-height: 1;
}
.text-blue-600 {
    --tw-text-opacity: 1;
    color: rgb(37 99 235 / var(--tw-text-opacity));
}
.text-destructive-foreground {
    color: hsl(var(--destructive-foreground));
}
.text-gray-500 {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity));
}
.text-gray-600 {
    --tw-text-opacity: 1;
    color: rgb(75 85 99 / var(--tw-text-opacity));
}
.text-inherit {
    color: inherit;
}
.text-muted-foreground {
    color: hsl(var(--muted-foreground));
}
.text-popover-foreground {
    color: hsl(var(--popover-foreground));
}
.text-primary {
    color: hsl(var(--primary));
}
.text-primary-foreground {
    color: hsl(var(--primary-foreground));
}
.text-secondary-foreground {
    color: hsl(var(--secondary-foreground));
}
.underline {
    text-decoration-line: underline;
}
.underline-offset-4 {
    text-underline-offset: 4px;
}
.opacity-0 {
    opacity: 0;
}
.opacity-100 {
    opacity: 1;
}
.shadow-\[rgb\(15_15_15_\/_5\%\)_0_0_0_1px\2c _rgb\(15_15_15_\/_10\%\)_0_3px_6px\2c _rgb\(15_15_15_\/_20\%\)_0_9px_24px\] {
    --tw-shadow: rgb(15 15 15 / 5%) 0 0 0 1px, rgb(15 15 15 / 10%) 0 3px 6px, rgb(15 15 15 / 20%) 0 9px 24px;
    --tw-shadow-colored: 0 0 0 1px var(--tw-shadow-color), 0 3px 6px var(--tw-shadow-color), 0 9px 24px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-md {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
}
.outline {
    outline-style: solid;
}
.ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.ring-ring {
    --tw-ring-color: hsl(var(--ring));
}
.ring-offset-2 {
    --tw-ring-offset-width: 2px;
}
.ring-offset-background {
    --tw-ring-offset-color: hsl(var(--background));
}
.blur {
    --tw-blur: blur(8px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.filter {
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.backdrop-blur-\[4px\] {
    --tw-backdrop-blur: blur(4px);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
            backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}
.transition-colors {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.transition-transform {
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.duration-200 {
    transition-duration: 200ms;
}
@keyframes enter {

    from {
        opacity: var(--tw-enter-opacity, 1);
        transform: translate3d(var(--tw-enter-translate-x, 0), var(--tw-enter-translate-y, 0), 0) scale3d(var(--tw-enter-scale, 1), var(--tw-enter-scale, 1), var(--tw-enter-scale, 1)) rotate(var(--tw-enter-rotate, 0));
    }
}
@keyframes exit {

    to {
        opacity: var(--tw-exit-opacity, 1);
        transform: translate3d(var(--tw-exit-translate-x, 0), var(--tw-exit-translate-y, 0), 0) scale3d(var(--tw-exit-scale, 1), var(--tw-exit-scale, 1), var(--tw-exit-scale, 1)) rotate(var(--tw-exit-rotate, 0));
    }
}
.duration-200 {
    animation-duration: 200ms;
}
.file\:border-0::file-selector-button {
    border-width: 0px;
}
.file\:bg-background::file-selector-button {
    background-color: hsl(var(--background));
}
.file\:text-sm::file-selector-button {
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.file\:font-medium::file-selector-button {
    font-weight: 500;
}
.placeholder\:text-muted-foreground::-moz-placeholder {
    color: hsl(var(--muted-foreground));
}
.placeholder\:text-muted-foreground::placeholder {
    color: hsl(var(--muted-foreground));
}
.after\:flex::after {
    content: var(--tw-content);
    display: flex;
}
.after\:h-16::after {
    content: var(--tw-content);
    height: 4rem;
}
.after\:w-\[3px\]::after {
    content: var(--tw-content);
    width: 3px;
}
.after\:rounded-\[6px\]::after {
    content: var(--tw-content);
    border-radius: 6px;
}
.after\:bg-ring::after {
    content: var(--tw-content);
    background-color: hsl(var(--ring));
}
.after\:opacity-0::after {
    content: var(--tw-content);
    opacity: 0;
}
.after\:content-\[\'_\'\]::after {
    --tw-content: ' ';
    content: var(--tw-content);
}
.hover\:bg-accent:hover {
    background-color: hsl(var(--accent));
}
.hover\:bg-destructive\/90:hover {
    background-color: hsl(var(--destructive) / 0.9);
}
.hover\:bg-muted:hover {
    background-color: hsl(var(--muted));
}
.hover\:bg-primary\/90:hover {
    background-color: hsl(var(--primary) / 0.9);
}
.hover\:bg-secondary\/80:hover {
    background-color: hsl(var(--secondary) / 0.8);
}
.hover\:text-accent-foreground:hover {
    color: hsl(var(--accent-foreground));
}
.hover\:text-gray-800:hover {
    --tw-text-opacity: 1;
    color: rgb(31 41 55 / var(--tw-text-opacity));
}
.hover\:text-muted-foreground:hover {
    color: hsl(var(--muted-foreground));
}
.hover\:underline:hover {
    text-decoration-line: underline;
}
.focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
}
.focus-visible\:outline-none:focus-visible {
    outline: 2px solid transparent;
    outline-offset: 2px;
}
.focus-visible\:ring-2:focus-visible {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.focus-visible\:ring-ring:focus-visible {
    --tw-ring-color: hsl(var(--ring));
}
.focus-visible\:ring-transparent:focus-visible {
    --tw-ring-color: transparent;
}
.focus-visible\:ring-offset-2:focus-visible {
    --tw-ring-offset-width: 2px;
}
.disabled\:pointer-events-none:disabled {
    pointer-events: none;
}
.disabled\:cursor-not-allowed:disabled {
    cursor: not-allowed;
}
.disabled\:opacity-50:disabled {
    opacity: 0.5;
}
.group:hover .group-hover\:opacity-100 {
    opacity: 1;
}
.group:hover .group-hover\:after\:opacity-100::after {
    content: var(--tw-content);
    opacity: 1;
}
.data-\[state\=on\]\:bg-accent[data-state=on] {
    background-color: hsl(var(--accent));
}
.data-\[state\=on\]\:text-accent-foreground[data-state=on] {
    color: hsl(var(--accent-foreground));
}
.data-\[state\=open\]\:animate-in[data-state=open] {
    animation-name: enter;
    animation-duration: 150ms;
    --tw-enter-opacity: initial;
    --tw-enter-scale: initial;
    --tw-enter-rotate: initial;
    --tw-enter-translate-x: initial;
    --tw-enter-translate-y: initial;
}
.data-\[state\=closed\]\:animate-out[data-state=closed] {
    animation-name: exit;
    animation-duration: 150ms;
    --tw-exit-opacity: initial;
    --tw-exit-scale: initial;
    --tw-exit-rotate: initial;
    --tw-exit-translate-x: initial;
    --tw-exit-translate-y: initial;
}
.data-\[state\=closed\]\:fade-out-0[data-state=closed] {
    --tw-exit-opacity: 0;
}
.data-\[state\=open\]\:fade-in-0[data-state=open] {
    --tw-enter-opacity: 0;
}
.data-\[state\=closed\]\:zoom-out-95[data-state=closed] {
    --tw-exit-scale: .95;
}
.data-\[state\=open\]\:zoom-in-95[data-state=open] {
    --tw-enter-scale: .95;
}
.data-\[side\=bottom\]\:slide-in-from-top-2[data-side=bottom] {
    --tw-enter-translate-y: -0.5rem;
}
.data-\[side\=left\]\:slide-in-from-right-2[data-side=left] {
    --tw-enter-translate-x: 0.5rem;
}
.data-\[side\=right\]\:slide-in-from-left-2[data-side=right] {
    --tw-enter-translate-x: -0.5rem;
}
.data-\[side\=top\]\:slide-in-from-bottom-2[data-side=top] {
    --tw-enter-translate-y: 0.5rem;
}
@media print {

    .print\:placeholder\:text-transparent::-moz-placeholder {
        color: transparent;
    }

    .print\:placeholder\:text-transparent::placeholder {
        color: transparent;
    }
}
.\[\&\:\:-webkit-scrollbar-button\]\:hidden::-webkit-scrollbar-button {
    display: none;
}
.\[\&\:\:-webkit-scrollbar-button\]\:h-0::-webkit-scrollbar-button {
    height: 0px;
}
.\[\&\:\:-webkit-scrollbar-button\]\:w-0::-webkit-scrollbar-button {
    width: 0px;
}
.\[\&\:\:-webkit-scrollbar-thumb\]\:min-h-\[65px\]::-webkit-scrollbar-thumb {
    min-height: 65px;
}
.\[\&\:\:-webkit-scrollbar-thumb\]\:rounded-2xl::-webkit-scrollbar-thumb {
    border-radius: 1rem;
}
.\[\&\:\:-webkit-scrollbar-thumb\]\:border-4::-webkit-scrollbar-thumb {
    border-width: 4px;
}
.\[\&\:\:-webkit-scrollbar-thumb\]\:border-white::-webkit-scrollbar-thumb {
    --tw-border-opacity: 1;
    border-color: rgb(255 255 255 / var(--tw-border-opacity));
}
.\[\&\:\:-webkit-scrollbar-track\]\:border-0::-webkit-scrollbar-track {
    border-width: 0px;
}
.\[\&\:\:-webkit-scrollbar\]\:w-4::-webkit-scrollbar {
    width: 1rem;
}
.focus\:\[\&\:\:placeholder\]\:opacity-0:focus::-moz-placeholder {
    opacity: 0;
}
.focus\:\[\&\:\:placeholder\]\:opacity-0:focus::placeholder {
    opacity: 0;
}
.\[\&_svg\:not\(\[data-icon\]\)\]\:h-5 svg:not([data-icon]) {
    height: 1.25rem;
}
.\[\&_svg\:not\(\[data-icon\]\)\]\:w-5 svg:not([data-icon]) {
    width: 1.25rem;
}

